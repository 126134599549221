.nav-section-menu {

  .nav-link {
    color: $body-color;

    &.active,
    &:hover {
      font-weight: bold;
    }

    border-bottom: 2px solid transparent;

    &.active {
      border-bottom-color: $primary;
    }

    @include media-breakpoint-up(lg) {
      border-bottom: none;
      border-left: 4px solid transparent;
      margin-bottom: 1rem;

      &.active {
        border-left-color: $primary;
      }
    }
  }

}

.sticky-top-section {

  @include media-breakpoint-up(lg) {
    position: sticky;
    background-color: rgba($body-bg, .9);
    top: $navbar-top-height;
    z-index: $zindex-sticky - 5;

    .main-with-warning & {
      top: $navbar-top-height + $status-top-height;
    }
  }

}

.section-with-sidebar {

  @include media-breakpoint-up(lg) {
    display: flex;

    .section-sidebar {
      flex: 0 0 $sidebar-width;
    }

    .section-content {
      flex: 1 1;
      width: calc(100% - #{$sidebar-width});
      max-width: calc(100% - #{$sidebar-width});
    }
  }
}
