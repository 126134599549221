@use "sass:math";

$cards-per-line: (xs: 1,
  sm: 2,
  md: 3,
  lg: 4);

.list-group.list-group-deck {

  .list-group-item {
    padding: math.div($grid-gutter-width, 2);
  }

  @each $breakpoint,
  $cards in $cards-per-line {

    @include media-breakpoint-up($breakpoint) {
      .list-group-item {
        width: math.div(100%, $cards);
      }
    }
  }

}
