@import '../../general/general-variables';

//
// Color system
//

$blue: #040066;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #C6006E;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #00d4ff;
$cyan20: #86e3fc;

$primary: $blue;
$secondary: $gray-200;
$tertiary: $blue;
$quaternary: $cyan;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$theme-colors: (
);
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
      "secondary": $secondary,
      "tertiary": $tertiary,
      "quaternary": $quaternary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "light": $light,
      "dark": $dark),
    $theme-colors);

// Forms
$input-bg: $white;


// Links
//
// Style anchor elements.

$link-color: $tertiary;
$link-hover-color: darken($link-color, 15%);

// Components
//
// Define common padding and border radius sizes and more.

$component-active-color: $primary;
$component-active-bg: $quaternary !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'NotoSans',
  Arial,
  sans-serif;
$font-family-base: $font-family-sans-serif;

// Overwritting own vars, defaults are in /styles/general/own_vars
$navbar-link-color-active: $quaternary;