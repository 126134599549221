//
// Color system
//

$white: #fff !default;
$gray-100: #F7F9FA !default;
$gray-200: #E7E6E6 !default;
$gray-300: #D8D8D8 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #696d73 !default;
$gray-700: #495057 !default;
$gray-800: #3D3D3D !default;
$gray-900: #212529 !default;
$black: #000 !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-100 !default;
$body-color: $gray-800 !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.2 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG/#contrast-minimum
$min-contrast-ratio: 3 !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 760px,
  md: 980px,
  lg: 1184px,
  xl: 1600px) !default;


// Components
//
// Define common padding and border radius sizes and more.

$border-radius: .15rem !default;
$border-radius-lg: .2rem !default;
$border-radius-sm: .1rem !default;

// Forms
$input-bg: $white !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-weight-bolder: 900 !default;
$headings-font-weight: 700 !default;
$small-font-size: .8em !default;