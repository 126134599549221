$font-size-xl: $font-size-base * 1.33 !default;

$login-bg: $primary !default;

$navbar-top-height: 4rem !default;
$navbar-link-color: $gray-200 !default;
$navbar-link-color-active: $white !default;

$status-top-height: 3rem !default;

$sidebar-width: 12rem !default;

$dashboard-card-min-height: 150px;