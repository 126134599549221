@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Material-Symbols-Outlined.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Symbols Filled';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Material-Symbols-Filled.woff2') format('woff2');
}

@keyframes material-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.material-symbols-outlined,
.material-symbols-filled {
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  &.spin {
    animation-name: material-spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
}

.material-symbols-filled {
  font-family: 'Material Symbols Filled';
}
