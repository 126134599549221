// Theme fonts
@import './fonts';
// Theme variables
@import './variables';
// General imports
@import '../../general/imports';

.nav-pills {
  font-weight: 400;
}

.btn-action:hover {
  background-color: $quaternary;
  color: $primary;
}

.btn-outline-tertiary {
  &:hover {
    background-color: $quaternary;
    color: $primary;
  }

  &:disabled {
    background-color: transparent;
  }
}

.header-navbar .navbar-nav .nav-item {
  &.navbar-item-user {
    &.switched {

      .nav-link-button,
      .nav-link>button {
        color: $primary !important;
      }

      background-color: $quaternary;

      &:hover {
        background-color: $cyan20;
      }
    }
  }

  .dropdown-menu.section-menu {
    .dropdown-item {

      &.active,
      &:hover {
        color: $quaternary;
        background-color: $tertiary;
      }
    }
  }
}
