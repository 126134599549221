.login-content {
  position: absolute;
  width: 100%;
  min-height: 100%;
  background-color: $login-bg;


  .login-logo {
    margin-top: 60px;

    img {
      max-height: 100px;
    }
  }

  .login-elements {
    .flex-column>div {
      max-width: 40rem;
    }
  }

  .login-form {
    padding: 1.5rem;
    background-color: white;
    width: 400px;
  }

  p {
    font-size: .9em;
  }

}
