$status-colors: (
  active: #00962f,
  inactive: #fa3031,
  deleted: #fa3031,
  cancelled: #fa3031,
  hold: #e9a800,
  suspended: #e9a800,
  pending: #e9a800,
  preactive: #007aff,
  running: #007aff,
  new: #007aff,
  in-process: #e9a800,
  processing: #e9a800,
  completed: #00962f,
  enabled: #00962f,
  discovered: #8B8000,
  onboarded: #00962f,
  linked: #FFA500,
  provisioned: #007aff,
  unlinked: grey,
  disabled: #e9a800,
  terminated: grey,
  monitoring:darkcyan,
  update:#e9a800,
  resolved: #00962f,
  other:black,
);

@each $color,
$value in $status-colors {
  .badge-status-#{$color} {
    @include badge-variant($value);
  }

  .btn-#{$color} {
    @include badge-variant($value);
  }
}

@each $color,
$value in $status-colors {
  // @include text-emphasis-variant('.text-status-#{$color}', $value, true);
}

.circle-indicator {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
}

@each $color,
$value in $status-colors {
  .circle-indicator-#{$color} {
    background-color: $value;
  }
}

$state-badgets: (
  onstock: (bg: white,
    border: black,
    icon: 'cloud',
  ),
  enabled: (bg: black,
    border: grey,
    icon: 'download',
  ),
  disabled: (bg: grey,
    border: black,
    icon: 'download',
  ),
  created: (bg: #e9a800,
    border: grey,
    icon: "exclamation",
  ),
  root: (bg: black,
    border: grey,
    icon: "devices"
  ),
  downloading: (bg: black,
    border: grey,
    icon: "schedule"
  ),
  installed: (bg: black,
    border: grey,
    icon: 'download',
  ),
  disabling: (bg: grey,
    border: black,
    icon: "schedule"
  ),
  deleting: (bg: grey,
    border: black,
    icon: "schedule"
  ),
  available: (bg: white,
    border: black,
    icon: "cloud",
  ),
  locked: (bg: #e9a800,
    border: grey,
    icon: "lock",
  ),
  allocated: (bg: cornflowerblue,
    border: grey,
    icon: "link",
  ),
  linked: (bg: cornflowerblue,
    border: grey,
    icon: "link",
  ),
  confirmed: (bg: darkcyan,
    border: grey,
    icon: "check",
  ),
  released: (bg: gray,
    border: black,
    icon: 'progress_activity',
  ),
  downloaded: (bg: grey,
    border: black,
    icon: 'download',
  ),
  error: (bg: red,
    border: black,
    icon: "error",
  ),
);

@each $state,
$value in $state-badgets {
  .badge-state-#{$state} {
    @include badge-variant(map-get($value, 'bg'));
    border: 2px solid map-get($value, 'border');

    &:before {
      content: map-get($value, 'icon');
      font-family: 'Material Symbols Outlined';
      margin-right: 0.3em;
    }
  }
}
