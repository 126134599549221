@use "sass:math";

// Bootstrap overrides
.was-validated {

  .form-control,
  .form-check-input {
    &.ng-invalid {
      @extend .is-invalid;
    }
  }

  ng-select.ng-invalid .ng-select-container {
    border-color: $form-feedback-invalid-color;
  }
}

.invalid-feedback {
  display: block;
}

label {
  font-weight: 600;

  &.form-check-label {
    font-weight: inherit;
  }
}

.input-group> :not(:first-child) {
  &.ng-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    >.ng-select-container {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

// Form control with an icon inside
.form-control-inside-icon {
  position: relative;

  .inside-icon {
    position: absolute;
    right: 0;
    top: 0;
    color: map-get($theme-colors, primary);
    padding: $input-btn-padding-y $input-btn-padding-x;
    font-size: $font-size-base;
    line-height: $input-btn-line-height;

    &.inside-icon-lg {
      padding: $input-btn-padding-y-lg $input-btn-padding-x;
      font-size: $font-size-lg;
    }

    &.inside-icon-sm {
      padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
      font-size: $font-size-sm;
    }
  }

  .form-control {
    padding-right: 2em;
  }
}

.dropdown-wrapper {
  .dropdown-menu {
    width: 95%;
    max-height: 20rem;
    overflow: auto;
  }
}

.advanced-search-fieldset {
  @extend .p-3 !optional;
  border: 1px solid map-get($theme-colors, primary);
}

[required] {
  >label {
    &::after {
      content: ' *';
      color: red;
    }
  }
}

label.required {
  &::after {
    content: ' *';
    color: red;
  }
}

label.form-label {

  &:has(+ *:is(:required)),
  &:has(+ *:is([required])) {
    &::after {
      content: ' *';
      color: red;
    }
  }
}

$form-switch-lg-width: 3rem;
$form-switch-lg-box-width: $form-switch-lg-width+0.5rem;

.form-switch {
  &.form-switch-green-red {
    $form-switch-green-red-color: rgba($white, 1);

    .form-check-input {
      border: none;
      background-color: #ef4a4a;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-green-red-color}'/></svg>");

      &:checked {
        background-color: #58ea75;
      }
    }
  }

  &.form-switch-lg {
    padding-left: $form-switch-padding-start * 2;

    .form-check-input {
      width: $form-switch-width * 2;
      margin-left: $form-switch-padding-start * -2;
      height: $form-check-input-width * 2;
    }
  }
}

.hyperlink-text {
  text-decoration: underline;
  color: blue;
}

ng-select.scrollable>div.ng-select-container {
  overflow-y: auto;
  max-height: 200px;
}

.ql-container {
  min-height: 20rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}