.header-navbar {
  padding: 0 .5rem;
  min-height: $navbar-top-height;

  .navbar-brand {
    padding: .25rem 0;
    max-width: 40%;
    margin-bottom: 0;

    a {
      color: $navbar-link-color;

      &:hover {
        color: $navbar-link-color-active;
        text-decoration: none;
      }
    }

    img {
      max-height: $navbar-top-height - .5rem;

      &.not-scrolled {
        display: inherit;
      }

      &.scrolled {
        display: none;
      }
    }
  }

  .navbar-not-collapse {
    .nav-item {
      padding: 0 .25rem;
      margin: 0 .25rem;
    }

    .navbar-nav .dropdown-menu {
      margin-top: 1rem;
      position: absolute;
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link>button {
        padding: 0;
        color: $navbar-link-color;
        background: none;
        border: none;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      .nav-link-button {
        color: $navbar-link-color;

        .nav-link-icon {
          margin-right: .5rem;
        }
      }

      .dropdown-menu.section-menu {
        background-color: $primary;
        border: none;
        border-top: 1px solid $gray-200;
        border-radius: 0;

        .dropdown-header {
          color: $gray-200;
        }

        .dropdown-item {
          color: $gray-200;
          font-weight: 300;

          &:hover {
            background-color: $gray-200;
            color: $primary;
          }

          &.active {
            color: $gray-200;
            background-color: $tertiary;
          }
        }

        .account-selector-wrapper .dropdown-menu {
          max-height: 20rem;
          overflow: auto;

          .dropdown-item {
            color: $body-color;

            &.active {
              color: color-contrast($primary);
              background-color: $primary;
            }
          }
        }
      }

      &.active,
      &:hover {

        .nav-link>button,
        .nav-link-button {
          color: $navbar-link-color-active;
        }
      }
    }
  }

  &.scrolled {
    .navbar-brand>img {
      &.not-scrolled {
        display: none;
      }

      &.scrolled {
        display: inherit;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-nav .nav-item {
      .nav-link {
        padding: 0 .7rem;
      }

      .nav-link>button {
        &.dropdown-toggle {
          padding-right: .75rem;
          position: relative;

          &::after {
            position: absolute;
            display: block;
            right: 0;
            top: 50%;
          }
        }
      }

      .nav-link-button {
        display: inline-block;
        text-align: center;

        .nav-link-icon {
          display: block;
          margin-right: 0;
          font-size: 150%;
        }

        .nav-link-icon.big {
          font-size: 220%;
        }

        .nav-link-badge {
          position: absolute;
          top: 0;
          right: 0;
        }

        .nav-link-label {
          display: block;
          font-size: 70%;
        }

        &.nav-link-label-2-lines {
          display: block;
          line-height: 1rem;
        }

        .nav-link-content-max-width {
          max-width: 8em;
        }
      }
    }
  }
}

.navbar-item-user.switched {
  background-color: $tertiary;
}

.header-status {
  height: $status-top-height;
}

.footer-navbar {
  background-color: white;
}