$notosans-weights: 100 300 400 700 900;

@each $weight in $notosans-weights {
  @font-face {
    font-family: 'NotoSans';
    font-weight: $weight;
    font-style: normal;
    src: url('/assets/fonts/NotoSans-#{$weight}.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NotoSans';
    font-weight: $weight;
    font-style: italic;
    src: url('/assets/fonts/NotoSans-#{$weight}-Italic.ttf') format('truetype');
  }
}
