// Width
.w-1 {
  width: 1% !important;
}

// Height
.height-100vh {
  min-height: 100vh;
}

// Font size
.font-size-xl {
  font-size: $font-size-xl;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-md {
  font-size: $font-size-base;
}

.font-size-sm {
  font-size: $font-size-sm;
}

// Cursor
.cursor-pointer {
  cursor: pointer;
}

.text-break {
  overflow-wrap: break-word;
  word-break: break-word;
}

.text-break-all {
  overflow-wrap: break-word;
  word-break: break-all;
}

//Usage bar
.sim-usage-bar {
  position: relative;
  height: 2rem;
  width: 6rem;
  background-color: $red;

  .initial-quantity {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $blue;
  }

  .total-cost {
    position: absolute;
    top: .7rem;
    bottom: .7rem;
    left: 0;
    background-color: $cyan;
  }

  .prorata {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $green;
    opacity: .5;
  }
}

// Advanced search accordion
.advanced-search-accordion {
  .accordion-item {
    border: none;
    background-color: transparent;

    .accordion-button {
      padding-left: 1rem;
      background-color: transparent;
      color: $primary;

      &:before {
        content: "keyboard_arrow_down";
        font-family: 'Material Symbols Outlined';
      }

      &:after {
        content: none;
      }

      &.collapsed:before {
        content: "chevron_right";
      }
    }
  }
}

// Wizard steps indicator
.wizard-step {
  font-size: 2rem;
  line-height: 3rem;
  width: 3rem;
  height: 3rem;
  color: $light;
  border: 1px solid map-get($theme-colors, secondary);
  background-color: $white;
  text-align: center;

  &.active {
    color: map-get($theme-colors, tertiary);
  }
}

.service-selector {
  .btn {
    margin: .5rem;
    line-height: 3;
    padding: 1rem 2rem;
  }
}

// Summary
.summary-box {
  background-color: #eee;
  border: .1rem solid #aaa;
  border-radius: .1rem;
  width: 9.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;

  p {
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

// Preview logo
.preview-logo {
  max-width: 10rem;
}

// address selector
.address-selector {
  .btn {
    margin: .5rem;
    width: 20%;
    height: 300px;
    text-align: left;

    >ul {
      margin: 0;
      padding: 0;

      >li {
        list-style: none;
      }
    }
  }
}

// Card for widgets selector
.card-widget {
  height: 100%;
  color: map-get($theme-colors, primary);
  border-color: map-get($theme-colors, primary);

  &.cursor-pointer:hover {
    color: $white;
    background-color: map-get($theme-colors, primary);
  }

  .card-img-top {
    text-align: center;
    font-size: 300%;
  }

  .card-body {
    text-align: center;
    padding: $spacer;
  }
}

.export-indicator-modal-label {
  color: $gray-500 !important;
}