.btn-column {
  @extend .d-inline-flex !optional;
  @extend .flex-column !optional;
  @extend .align-items-center !optional;
}

.btn-big-icon {

  >.material-symbols-outlined,
  >.material-symbols-filled {
    font-size: 180%;
  }
}

.btn-action {
  @include button-outline-variant(map-get($theme-colors, tertiary));
  @extend .btn-column;
  border: none;

  >.material-symbols-outlined,
  >.material-symbols-filled {
    font-size: 150%;
  }
}

.btn-input {
  @include button-variant($input-bg, $input-border-color);
}

.btn {

  >.material-symbols-outlined,
  >.material-symbols-filled {
    line-height: $btn-line-height;
  }
}

.actions-column {
  width: 100%;

  // >* {
  //   width: 100%;
  // }

  >*+* {
    margin-top: 1rem;
  }

  button {
    width: 100%;
  }
}
