.table.table-pod {
  font-size: 80%;
  border-top: none;

  thead {
    border-top: none;

    tr:first-child th {
      border-top: none;
    }

    th {
      padding-top: .25rem;
      padding-bottom: .25rem;
      border-bottom-width: 1px;
      border-bottom-color: $gray-600;
    }
  }

  th,
  td {
    vertical-align: middle;
    border-top: none;
    border-bottom: $table-border-width solid $table-border-color;
  }
}


.table.table-ordered {

  .column-ordered {
    cursor: pointer;
    position: relative;
    padding-right: 3rem;

    &:after {
      display: inline-block;
      font-family: 'Material Symbols Outlined';
      position: absolute;
      right: 1.5rem;
      color: $tertiary;
    }

    &:hover:after {
      content: 'sort';
      opacity: .5;
    }

    &.column-ordered-asc:after {
      content: 'sort';
      transform: rotate(180deg) scaleX(-1);
    }

    &.column-ordered-desc:after {
      content: 'sort';
    }
  }

}

.table.table-no-border {

  &,
  tr,
  th,
  td {
    border: none;
  }
}

.pod-paginator ul {
  margin-bottom: 0;
}

.pod-pagination .pagination {

  margin-bottom: 0;

  .page-link {
    margin-right: .5rem;
    font-size: .75rem;
    color: $white;
    background-color: $gray-300;
    border: none;

    &:hover {
      color: $tertiary;
    }
  }

  .page-item.active .page-link {
    color: $tertiary;
  }

  .page-item.disabled .page-link {
    color: $gray-200;
  }

}

.table.pod-table {
  .pod-table-cell.pod-table-sticky {
    background-color: $white;

    &.pod-table-sticky-border-elem-left {
      box-shadow: inset -5px 0px 10px -5px $table-border-color;
    }
  }

  &.table-hover tr:hover .pod-table-cell.pod-table-sticky {
    background-color: #ececec;
  }
}
