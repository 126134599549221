.dashboard {

  .dashboard-card {

    >.card-header {

      .move-handler {
        cursor: move;
        user-select: none;

        &:hover:before {
          font-family: 'Material Symbols Outlined';
          content: "drag_pan";
          margin-right: .5rem;
        }
      }

    }

    >.card-body {
      min-height: $dashboard-card-min-height;
    }

    &.expanded .card-body {
      min-height: $dashboard-card-min-height * 2;
    }

  }

}
